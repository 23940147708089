import { useEffect } from 'react';

import {
  ContractBRCltEntryDuracao,
  ContractBRCltEntryRegime,
} from '@octopus/api';
import { TipoContrato } from '@octopus/esocial/mapper';
import {
  calculateDaysDifferenceBetweenDates,
  formatBooleanBR,
  formatDateBR,
  formatNumberOfDays,
} from '@octopus/formatters';

import { useFFlags } from '../../../../../app/fflags';
import { Record, RecordEntry, mapperToOptions } from '../../../Record';
import { BaseRecordProps, booleanOptions } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type DuracaoDoContratoData = {
  duracao: ContractBRCltEntryDuracao;
  regime: ContractBRCltEntryRegime;
};

export type DuracaoDoContratoProps = BaseRecordProps<DuracaoDoContratoData>;

export function DuracaoDoContrato(props: DuracaoDoContratoProps) {
  const { FFlags } = useFFlags();
  const { duracao, regime } = props.data;

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit(props);

  const prepareOnChange = (
    key: string,
    formatter: (val: string) => string | number | boolean = (val) => val,
  ) => {
    return (value: string) =>
      updateData((data) => ({
        ...data,
        duracao: {
          ...data.duracao,
          [key]: formatter(value),
        },
      }));
  };

  useEffect(() => {
    if (formData.duracao.tpContr === 1) {
      updateData((data) => ({
        ...data,
        duracao: {
          ...data.duracao,
          dtTerm: null,
          clauAssec: null,
          objDet: null,
        },
      }));
    }
  }, [formData.duracao.tpContr]);

  return (
    <Record title="Duração do contrato" edit={editRecordProps}>
      <RecordEntry
        label="Tipo de contrato"
        edit={{
          editing,
          type: 'options',
          onChange: prepareOnChange('tpContr', parseInt),
          value: formData.duracao.tpContr,
          options: mapperToOptions({ mapper: TipoContrato }),
          hasError: hasError('br/duracao/tpContr'),
        }}
      >
        {TipoContrato.getByCode(duracao.tpContr)}
      </RecordEntry>
      <RecordEntry
        label="Data do término"
        hide={
          (!editing && duracao.tpContr === 1) ||
          (editing && formData.duracao.tpContr === 1)
        }
        edit={{
          editing,
          type: 'date',
          valueFormat: 'YYYY-MM-DD',
          value:
            formData.duracao.tpContr === 1
              ? undefined
              : formData.duracao.dtTerm,
          onChange: prepareOnChange('dtTerm'),
          disabled: formData.duracao.tpContr === 1,
          hasError: hasError('br/duracao/dtTerm'),
        }}
      >
        {duracao.dtTerm ? formatDateBR(duracao.dtTerm) : undefined}
      </RecordEntry>
      <RecordEntry
        label="Contém cláusula assecuratória"
        hide={
          (!editing && duracao.tpContr === 1) ||
          (editing && formData.duracao.tpContr === 1)
        }
        edit={{
          type: 'options',
          editing,
          value: formData.duracao.clauAssec?.toString(),
          onChange: prepareOnChange('clauAssec', (str) => str === 'true'),
          options: booleanOptions,
          disabled: formData.duracao.tpContr === 1,
          hasError: hasError('br/duracao/clauAssec'),
        }}
      >
        {formatBooleanBR(duracao.clauAssec)}
      </RecordEntry>
      <RecordEntry
        label="Objeto razão da contratação temporária"
        hide={
          (!editing && duracao.tpContr === 1) ||
          (editing && formData.duracao.tpContr === 1)
        }
        edit={{
          editing,
          type: 'text',
          onChange: prepareOnChange('objDet'),
          value: formData.duracao.objDet,
          disabled: formData.duracao.tpContr === 1,
          hasError: hasError('br/duracao/objDet'),
        }}
      >
        {duracao.objDet}
      </RecordEntry>
      <FFlags isProbationPeriodVisible>
        <RecordEntry label="Período de experiência">
          {duracao.dtPerExpTerm
            ? `${formatNumberOfDays(calculateDaysDifferenceBetweenDates(regime.dtAdm, duracao.dtPerExpTerm) + 1)} \t até ${formatDateBR(duracao.dtPerExpTerm)}`
            : undefined}
        </RecordEntry>
        <RecordEntry label="Prorrogação do período de experiência">
          {duracao.dtPerExpProrTerm
            ? `${formatNumberOfDays(calculateDaysDifferenceBetweenDates(duracao.dtPerExpTerm, duracao.dtPerExpProrTerm) + 1)} \t até ${formatDateBR(duracao.dtPerExpProrTerm)}`
            : undefined}
        </RecordEntry>
      </FFlags>
    </Record>
  );
}
