import { useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  AssessmentOutlined,
  BeachAccessOutlined,
  Cancel,
  PeopleAltOutlined,
  PersonAddAltOutlined,
  PersonOutlined,
  PersonRemoveOutlined,
  ReceiptLongOutlined,
  RequestQuoteOutlined,
} from '@mui/icons-material';
import { Box, Button, CircularProgress, Typography } from '@mui/material';

import { MembershipTypes } from '@octopus/api';
import { ContractTypes, contractTypes } from '@octopus/contract-types';

import { PaymentRequestIcon } from '../modules/components/pj/icon';
import { MembershipContext } from '../modules/types';
import { membershipEquals } from '../utils';

import ChartsOfAccountsPage from './accounting/chartsOfAccounts/page';
import EntriesMappingsRulesPage from './accounting/entriesMappingsRules/page';
import { AdmissionDraftEditPage } from './admission/edit/page';
import { AdmissionDraftInputPage } from './admission/new/page';
import AdmissionListPage from './admission/page';
import { AdmissionDraftReviewPage } from './admission/review/page';
import CreateCertificate from './companies/certificates/page';
import CreateCompaniesPreferences from './companies/preferences/page';
import { AppContext } from './context';
import { ContractorCreatePaymentRequestPage } from './contractor/payment-requests/[payment-request]/create';
import { ContractorPaymentRequestPage } from './contractor/payment-requests/[payment-request]/page';
import { ContractorPaymentRequestListPage } from './contractor/payment-requests/page';
import { features, useFFlags, useResolveFFlags } from './fflags';
import { SignupSuccess } from './login/signup/success/page';
import { MembershipSelectorPage } from './memberships/selector/page';
import { Navbar, SidebarApp } from './navigation';
import Organizations from './organizations/page';
import { CreatePaymentRequestPage } from './payment-requests/[payment-request]/create';
import { PaymentRequestPage } from './payment-requests/[payment-request]/page';
import { PaymentRequestListPage } from './payment-requests/page';
import { PayslipPage } from './payrolls/[period]/[contractId]/[type]/payslip/page';
import { PayrollInputsSubmission } from './payrolls/[period]/[type]/inputs/[submissionId]/page';
import { PayrollInputs } from './payrolls/[period]/[type]/inputs/page';
import PayrollPage from './payrolls/[period]/[type]/page';
import Payrolls from './payrolls/page';
import People from './people/page';
import Person from './people/person/page';
import PersonalData from './personalData/page';
import { PayslipReceipt } from './receipts/[companyId]/[contractId]/[payrollId]/page';
import { Receipts } from './receipts/page';
import { ReportFormPage, ReportsPage } from './reports';
import Spreadsheets from './spreadsheets/page';
import TerminationReceiptPage from './terminations/[id]/page';
import TerminationsListPage from './terminations/page';
import UserInfo from './UserInfo';
import VacationRequestDetailsPage from './vacations/[contractId]/request/details/page';
import VacationRequestPage from './vacations/[contractId]/request/page';
import VacationSchedulerPage from './vacations/[contractId]/scheduler/page';
import VacationReceiptPage from './vacations/[id]/page';
import { VacationInputPage } from './vacations/new/page';
import NewVacationsPage from './vacations/newPage';
import VacationsPage from './vacations/page';

export function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const logout = () => navigate('/login/logout');
  const { FFlags, fflags } = useFFlags();
  const {
    isLoading,
    isError,
    error,
    appContext,
    membershipOptions,
    changeMembership,
  } = useContext(AppContext);

  useResolveFFlags();

  if (isLoading) {
    return <HomeLoading />;
  }
  if (
    isError ||
    !appContext?.user ||
    !appContext?.membership ||
    !membershipOptions
  ) {
    return <HomeError error={error} />;
  }

  const { email, personId, userId } = appContext.user;
  const { organizationId, companyId, membershipType } = appContext.membership;
  const { contractId, contractType } = appContext.contract ?? {};
  const { draftId } = appContext.admission ?? {};

  const apps = mountAppList({
    membershipType,
    personId,
    draftId,
    contractType,
    contractId,
  });

  const baseRoute = getBaseRoute(membershipType, draftId);

  const setMembership = (membership: MembershipContext) => {
    if (!membershipEquals(appContext?.membership, membership)) {
      const navigateTo = (() => {
        const parent = location.pathname.split('/')?.[1] ?? '';
        return getBaseRoute(membership?.membershipType, draftId, parent);
      })();
      changeMembership(membership);
      navigate(navigateTo);
    }
  };

  return (
    <Box height="100%">
      <ErrorBoundary FallbackComponent={PageErrorFallback}>
        <Navbar
          username={email}
          apps={apps}
          membership={appContext.membership}
          membershipOptions={membershipOptions}
          changeMembership={setMembership}
          logout={logout}
        />
        <Box
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              marginLeft: 7,
            },
            height: '100%',
          })}
          height={'100%'}
        >
          <Routes>
            <Route
              path="/payrolls"
              element={
                <Payrolls
                  organizationId={organizationId}
                  companyId={companyId}
                />
              }
            />
            <Route
              path="/payrolls/:period/:type"
              element={
                <PayrollPage
                  organizationId={organizationId}
                  companyId={companyId}
                />
              }
            />
            <Route
              path="/payrolls/:period/:type/inputs"
              element={
                <PayrollInputs
                  organizationId={organizationId}
                  companyId={companyId}
                />
              }
            />
            <Route
              path="/payrolls/:period/:type/inputs/:submissionId"
              element={
                <PayrollInputsSubmission
                  organizationId={organizationId}
                  companyId={companyId}
                />
              }
            />
            <Route
              path="/payrolls/:payrollId/payslips"
              element={
                <PayslipPage
                  organizationId={organizationId}
                  companyId={companyId}
                />
              }
            />
            <Route
              path="/vacations"
              element={
                <>
                  <FFlags canAccessVacationScheduler>
                    <NewVacationsPage
                      organizationId={organizationId}
                      companyId={companyId}
                    />
                  </FFlags>
                  <FFlags canAccessVacationScheduler={false}>
                    <VacationsPage
                      organizationId={organizationId}
                      companyId={companyId}
                    />
                  </FFlags>
                </>
              }
            />

            <Route
              path="/vacations/:payrollId"
              element={
                <VacationReceiptPage
                  organizationId={organizationId}
                  companyId={companyId}
                />
              }
            />
            <Route
              path="/vacations/new"
              element={
                <>
                  <FFlags canAccessVacationInputs>
                    <VacationInputPage
                      organizationId={organizationId}
                      companyId={companyId}
                    />
                  </FFlags>
                  <FFlags canAccessVacationInputs={false}>
                    <Navigate to={{ pathname: '/vacations' }} />
                  </FFlags>
                </>
              }
            />
            <Route
              path="/vacations/:contractId/scheduler"
              element={
                <>
                  <FFlags canAccessVacationScheduler>
                    <VacationSchedulerPage organizationId={organizationId} />
                  </FFlags>
                  <FFlags canAccessVacationScheduler={false}>
                    <Navigate to={{ pathname: baseRoute }} />
                  </FFlags>
                </>
              }
            />
            <Route
              path="/vacations/:contractId/request"
              element={
                <>
                  <FFlags canAccessVacationScheduler>
                    <VacationRequestPage organizationId={organizationId} />
                  </FFlags>
                  <FFlags canAccessVacationScheduler={false}>
                    <Navigate to={{ pathname: baseRoute }} />
                  </FFlags>
                </>
              }
            />
            <Route
              path="/vacations/:contractId/requestDetails/:sequence"
              element={
                <>
                  <FFlags canAccessVacationScheduler>
                    <VacationRequestDetailsPage
                      organizationId={organizationId}
                    />
                  </FFlags>
                  <FFlags canAccessVacationScheduler={false}>
                    <Navigate to={{ pathname: baseRoute }} />
                  </FFlags>
                </>
              }
            />
            <Route
              path="/admissions"
              element={
                <>
                  <FFlags canAccessAdmissionPage>
                    <AdmissionListPage
                      organizationId={organizationId}
                      companyId={companyId}
                    />
                  </FFlags>
                  <FFlags canAccessAdmissionPage={false}>
                    <Navigate to={{ pathname: baseRoute }} />
                  </FFlags>
                </>
              }
            />
            <Route
              path="/admissions/new"
              element={
                <>
                  <FFlags canAccessAdmissionPage>
                    <AdmissionDraftInputPage
                      organizationId={organizationId}
                      companyId={companyId}
                      companyContext={appContext?.company}
                      membershipProps={{
                        membershipType: membershipType,
                        membership: appContext.membership,
                        membershipOptions: membershipOptions,
                      }}
                      reviewMode={false}
                      finishReview={false}
                      probationPeriodEnabled={
                        fflags.isProbationPeriodVisible.enabled
                      }
                    />
                  </FFlags>
                  <FFlags canAccessAdmissionPage={false}>
                    <Navigate to={{ pathname: baseRoute }} />
                  </FFlags>
                </>
              }
            />
            <Route
              path="/admissions/new/:draftId"
              element={
                <>
                  <FFlags canAccessAdmissionPage>
                    <AdmissionDraftEditPage
                      organizationId={organizationId}
                      companyId={companyId}
                      companyContext={appContext?.company}
                      membershipProps={{
                        membershipType: membershipType,
                        membership: appContext.membership,
                        membershipOptions: membershipOptions,
                      }}
                    />
                  </FFlags>
                  <FFlags canAccessAdmissionPage={false}>
                    <Navigate to={{ pathname: baseRoute }} />
                  </FFlags>
                </>
              }
            />
            <Route
              path="/admissions/review/:draftId"
              element={
                <>
                  <FFlags canAccessAdmissionPage>
                    <AdmissionDraftReviewPage
                      organizationId={organizationId}
                      companyId={companyId}
                      companyContext={appContext?.company}
                    />
                  </FFlags>
                  <FFlags canAccessAdmissionPage={false}>
                    <Navigate to={{ pathname: baseRoute }} />
                  </FFlags>
                </>
              }
            />
            <Route
              path="/admissions/signup-success"
              element={
                <SignupSuccess
                  organizationId={organizationId}
                  userId={userId}
                />
              }
            />
            <Route
              path="/terminations"
              element={
                <TerminationsListPage
                  organizationId={organizationId}
                  companyId={companyId}
                />
              }
            />
            <Route
              path="/terminations/:payrollId"
              element={
                <TerminationReceiptPage
                  organizationId={organizationId}
                  companyId={companyId}
                />
              }
            />
            <Route
              path="/people"
              element={
                <People organizationId={organizationId} companyId={companyId} />
              }
            />
            <Route
              path="/people/:contractId"
              element={<Person organizationId={organizationId} />}
            />
            <Route
              path="/personal-data"
              element={
                <PersonalData
                  organizationId={organizationId}
                  personId={personId}
                  companyId={companyId}
                />
              }
            />
            <Route
              path="/receipts"
              element={
                <Receipts
                  organizationId={organizationId}
                  contractId={contractId}
                />
              }
            />
            <Route
              path="/receipts/:companyId/:contractId/:payrollId"
              element={<PayslipReceipt organizationId={organizationId} />}
            />
            <Route path="/" element={<Navigate to={baseRoute} replace />} />
            <Route path="/organizations" element={<Organizations />} />
            <Route
              path="/companies/certificates"
              element={<CreateCertificate organizationId={organizationId} />}
            />
            <Route
              path="/companies/preferences"
              element={
                <CreateCompaniesPreferences organizationId={organizationId} />
              }
            />
            <Route
              path="/spreadsheets"
              element={
                <Spreadsheets
                  organizationId={organizationId ?? ''}
                  companyId={companyId ?? ''}
                />
              }
            />
            <Route
              path="/reports"
              element={<ReportsPage organizationId={organizationId ?? ''} />}
            />
            <Route
              path="/reports/:reportId"
              element={<ReportFormPage organizationId={organizationId} />}
            />

            <Route
              path="/payment-requests"
              element={
                <>
                  <FFlags isPjEnabledForOrganization>
                    <PaymentRequestListPage
                      organizationId={organizationId}
                      companyId={companyId}
                    />
                  </FFlags>
                  <FFlags isPjEnabledForOrganization={false}>
                    <Navigate to={{ pathname: baseRoute }} />
                  </FFlags>
                </>
              }
            />
            <Route
              path="/payment-requests/new"
              element={
                <>
                  <FFlags isPjEnabledForOrganization>
                    <CreatePaymentRequestPage
                      organizationId={organizationId}
                      companyId={companyId}
                    />
                  </FFlags>
                  <FFlags isPjEnabledForOrganization={false}>
                    <Navigate to={{ pathname: baseRoute }} />
                  </FFlags>
                </>
              }
            />
            <Route
              path="/payment-requests/:paymentRequestId"
              element={
                <>
                  <FFlags isPjEnabledForOrganization>
                    <PaymentRequestPage
                      organizationId={organizationId}
                      companyId={companyId}
                    />
                  </FFlags>
                  <FFlags isPjEnabledForOrganization={false}>
                    <Navigate to={{ pathname: baseRoute }} />
                  </FFlags>
                </>
              }
            />

            <Route
              path="/accounting/charts-of-accounts"
              element={<ChartsOfAccountsPage organizationId={organizationId} />}
            />

            <Route
              path="/accounting/entries-mappings-rules/:period"
              element={
                <EntriesMappingsRulesPage organizationId={organizationId} />
              }
            />

            {contractId && (
              <>
                <Route
                  path="/contractor/payment-requests"
                  element={
                    <>
                      <FFlags isPjEnabledForOrganization>
                        <ContractorPaymentRequestListPage
                          organizationId={organizationId}
                          companyId={companyId}
                          contractId={contractId}
                        />
                      </FFlags>
                      <FFlags isPjEnabledForOrganization={false}>
                        <Navigate to={{ pathname: baseRoute }} />
                      </FFlags>
                    </>
                  }
                />
                <Route
                  path="/contractor/payment-requests/new"
                  element={
                    <>
                      <FFlags isPjEnabledForOrganization>
                        <ContractorCreatePaymentRequestPage
                          organizationId={organizationId}
                          companyId={companyId}
                          contractId={contractId}
                        />
                      </FFlags>
                      <FFlags isPjEnabledForOrganization={false}>
                        <Navigate to={{ pathname: baseRoute }} />
                      </FFlags>
                    </>
                  }
                />
                <Route
                  path="/contractor/payment-requests/:paymentRequestId"
                  element={
                    <>
                      <FFlags isPjEnabledForOrganization>
                        <ContractorPaymentRequestPage
                          organizationId={organizationId}
                          companyId={companyId}
                          contractId={contractId}
                        />
                      </FFlags>
                      <FFlags isPjEnabledForOrganization={false}>
                        <Navigate to={{ pathname: baseRoute }} />
                      </FFlags>
                    </>
                  }
                />
              </>
            )}
            <Route
              path="/memberships/selector"
              element={
                <MembershipSelectorPage
                  membership={appContext.membership}
                  membershipOptions={membershipOptions}
                  setMembershipContext={changeMembership}
                  logout={logout}
                />
              }
            />
          </Routes>
        </Box>
      </ErrorBoundary>
      <UserInfo
        organizationId={organizationId}
        companyId={companyId}
        userData={appContext.user}
      />
    </Box>
  );
}

function getBaseRoute(
  membershipType: MembershipTypes,
  draftId: string,
  parent?: string,
) {
  switch (membershipType) {
    case 'owner':
    case 'tako:support':
      return parent ? `/${parent}` : '/payrolls';
    case 'internal':
      return draftId != null ? `/admissions/new/${draftId}` : '/personal-data';
    case 'external':
      return '/personal-data';
  }
}

function mountAppList({
  membershipType,
  personId,
  draftId,
  contractType,
  contractId,
}: {
  membershipType: MembershipTypes;
  personId: string;
  draftId?: string;
  contractType?: ContractTypes;
  contractId?: string;
}): SidebarApp[] {
  const pjApps: SidebarApp[] = [];
  const cltApps: SidebarApp[] = [];
  const workerApps: SidebarApp[] = [];

  if (membershipType === 'internal' && contractType === contractTypes.brClt) {
    cltApps.push({
      title: 'Férias',
      path: `/vacations/${contractId}/scheduler`,
      Icon: BeachAccessOutlined,
      fflag: features.canAccessVacationScheduler,
    });
  }

  if (contractType === contractTypes.brClt) {
    cltApps.push({
      title: 'Holerites e Recibos',
      path: '/receipts',
      Icon: ReceiptLongOutlined,
    });
  }

  if (membershipType === 'internal' && contractType === contractTypes.brPj) {
    pjApps.push({
      title: 'Solicitações de Pagamento',
      path: '/contractor/payment-requests',
      Icon: PaymentRequestIcon(),
      fflag: features.isPjEnabledForOrganization,
    });
  }

  if (membershipType === 'owner' || membershipType === 'tako:support') {
    pjApps.push({
      title: 'Solicitações de Pagamento',
      path: '/payment-requests',
      Icon: PaymentRequestIcon(),
      fflag: features.isPjEnabledForOrganization,
    });
  }

  if (contractId) {
    workerApps.push({
      title: 'Meus Dados',
      path: '/personal-data',
      Icon: PersonOutlined,
    });
  }
  if (draftId) {
    workerApps.push({
      title: 'Minha Admissão',
      path: `/admissions/new/${draftId}`,
      Icon: PersonAddAltOutlined,
    });
  }

  if (membershipType === 'internal' && personId) {
    return [...workerApps, ...pjApps, ...cltApps];
  }

  if (membershipType === 'owner' || membershipType === 'tako:support') {
    cltApps.push(
      {
        title: 'Folhas de Pagamento',
        path: '/payrolls',
        Icon: RequestQuoteOutlined,
        fflag: features.isCltEnabledForOrganization,
      },
      {
        title: 'Férias',
        path: '/vacations',
        Icon: BeachAccessOutlined,
        fflag: features.isCltEnabledForOrganization,
      },
      {
        title: 'Admissões',
        path: '/admissions',
        Icon: PersonAddAltOutlined,
        fflag: features.canAccessAdmissionPage,
      },
      {
        title: 'Rescisões',
        path: '/terminations',
        Icon: PersonRemoveOutlined,
        fflag: features.isCltEnabledForOrganization,
      },
    );

    return [
      {
        title: 'Pessoas',
        path: '/people',
        Icon: PeopleAltOutlined,
      },
      ...cltApps,
      ...pjApps,
      {
        title: 'Relatórios',
        path: '/reports',
        Icon: AssessmentOutlined,
      },
      ...workerApps,
    ];
  }
  return [];
}

function HomeLoading() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <CircularProgress />
    </Box>
  );
}

function HomeError({ error }: { error: unknown }) {
  return (
    <Box
      position="fixed"
      top="50%"
      left="50%"
      sx={{
        transform: 'translate(-50%, -50%)',
      }}
      minWidth="280px"
    >
      <Box display="flex" alignItems="center" gap={1.8} pb={3}>
        <Cancel fontSize="huge" color="error" />
        <Typography variant="h1">Erro ao carregar organizações.</Typography>
      </Box>
      <Typography variant="body1">
        Não foi possível carregar suas organizações. Tente novamente mais tarde.
      </Typography>
      {error && <pre>{JSON.stringify(error, null, 2)}</pre>}
    </Box>
  );
}

function PageErrorFallback({ error }: { error: Error }) {
  console.error(`Failed to render page\n`, error);
  return (
    <Box
      position="fixed"
      top="50%"
      left="50%"
      sx={{
        transform: 'translate(-50%, -50%)',
      }}
      minWidth="280px"
    >
      <Box display="flex" alignItems="center" gap={1.8} pb={3}>
        <Cancel fontSize="huge" color="error" />
        <Typography variant="h1">Erro ao carregar a página.</Typography>
      </Box>
      <Typography variant="body1">
        Não foi possível carregar essa página. Tente novamente mais tarde.
      </Typography>
      <Typography variant="body1">
        Se o erro persistir, entre em contato com o suporte da Tako.
      </Typography>
      <Box pt={3} display="flex" justifyContent="flex-end">
        <Button
          color="primaryAlt"
          size="large"
          onClick={() => window.location.replace('/')}
        >
          Voltar
        </Button>
      </Box>
    </Box>
  );
}
