import { useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useQueries, useQuery } from '@tanstack/react-query';
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react';

import { Box, Button, Chip, Divider, Typography } from '@mui/material';

import {
  AccountingChartsOfAccounts,
  AccountingEntriesMappingRules,
  CompanyList,
  CompanySummary,
  CostCenterSummary,
  RubricsTableMap,
  fetchGetAllRubricTables,
  fetchPostEntriesMappingRulesCompile,
  fetchPutEntriesMappingRules,
  fetchSearchAllCostCenters,
  useGetAllCompanies,
  useGetChartsOfAccounts,
  useGetEntriesMappingRules,
} from '@octopus/api';
import { formatCNPJ } from '@octopus/formatters';
import {
  idsDasTabelasPadroes,
  lancamentosDeEncargos,
  lancamentosDerivados,
  rubricasESocial,
  rubricasProprietarias,
  tiposDeProvisoesPadroes,
} from '@octopus/payroll-engine/public-types/brazil';

import { DataFetching } from '../../../modules/dataFetching';

type RowData = AccountingEntriesMappingRules['rules'][number];

type CostCenters = {
  [key: string]: {
    // costCenterId
    costCenterId: CostCenterSummary['costCenterId'];
    name: CostCenterSummary['name'];
    code: CostCenterSummary['code'];
  };
};

type Companies = {
  [key: string]: {
    // companyId
    companyId: CompanySummary['companyId'];
    cnpj: CompanySummary['br']['cnpj'];
    razaoSocial: CompanySummary['br']['razaoSocial'];
  };
};

type Accounts = {
  [key: string]: {
    // chartId/accountId
    id: string;
    name: string;
  };
};

type AccountingEntries = {
  [key: string]: {
    id: string;
    type:
      | 'rubrica/provento'
      | 'rubrica/desconto'
      | 'rubrica/informativa'
      | 'rubrica/informativaDedutora'
      | 'encargo'
      | 'provisoes';
    name: string;
  };
};

function fromApiDataToRowData(
  data: AccountingEntriesMappingRules,
  entries: AccountingEntries,
): RowData[] {
  const makeEmptyFilter = () => ({
    contractId: [] as string[],
    legalEntityId: [] as string[],
    companyId: [] as string[],
    departmentId: [] as string[],
  });

  const makeEmptyMappings = () => ({
    description: '',
    source: [] as string[],
    credit: '',
    debit: '',
    allocation: '1',
  });

  const rows: RowData[] = [];

  data.rules.forEach((rule) => {
    const mappedEntries = new Set();

    rule.mappings.forEach((mapping) => {
      mapping.source.forEach((entryId) => {
        mappedEntries.add(entryId);
      });
    });

    rows.push({
      ...rule,
      filters: [...rule.filters, ...Array.from({ length: 3 }, makeEmptyFilter)],
      mappings: [
        ...rule.mappings,
        ...Object.keys(entries)
          .filter((entryId) => !mappedEntries.has(entryId))
          .map((entryId) => ({
            ...makeEmptyMappings(),
            source: [entryId],
          })),
      ],
    });
  });

  const makeEmptyRow = () => ({
    id: '',
    name: '',
    filters: Array.from({ length: 3 }, makeEmptyFilter),
    mappings: Object.keys(entries).map((entryId) => ({
      ...makeEmptyMappings(),
      source: [entryId],
    })),
  });

  Array.from({ length: 2 }).forEach(() => {
    rows.push(makeEmptyRow());
  });

  return rows;
}

function Page({ organizationId }: { organizationId: string }) {
  const { period } = useParams<{
    period: string;
  }>();

  const gridRef = useRef<AgGridReact>();

  const detailCellRenderer = useCallback(DetailCellRenderer, []);

  const queriesOptions = {
    refetchOnWindowFocus: false,
  };
  const companiesQuery = useGetAllCompanies(
    {
      pathParams: {
        organizationId,
      },
    },
    queriesOptions,
  );

  const allCompaniesIds = (companiesQuery.data?.data || []).map(
    (company) => company.companyId,
  );

  return (
    <Box sx={{ height: '100vh', pt: 4, px: 3 }}>
      <Typography variant="h1">Regras de Lançamentos Contábeis</Typography>

      <Box py={3}>
        <Divider />
      </Box>

      <Box height="90%">
        <DataFetching<{
          entriesMappingRules: AccountingEntriesMappingRules;
          costCenters: CostCenterSummary[];
          companies: CompanyList;
          chartsOfAccounts: AccountingChartsOfAccounts;
          allRubricTables: RubricsTableMap[];
        }>
          containerSx={{
            height: '100%',
          }}
          fetchResult={{
            results: {
              entriesMappingRules: useGetEntriesMappingRules(
                {
                  pathParams: {
                    periodId: period,
                    organizationId,
                  },
                },
                queriesOptions,
              ),
              costCenters: useQuery({
                ...queriesOptions,
                queryKey: ['searchAllCostCenters', organizationId],
                queryFn: async () => {
                  return (
                    await fetchSearchAllCostCenters({
                      pathParams: {
                        organizationId,
                      },
                      body: {
                        query: '',
                        pagination: {
                          size: 100,
                          page: 0,
                        },
                        sorting: {
                          field: 'name',
                          order: 'asc',
                        },
                      },
                    })
                  ).data;
                },
              }),
              companies: companiesQuery,
              chartsOfAccounts: useGetChartsOfAccounts(
                {
                  pathParams: {
                    organizationId,
                  },
                },
                queriesOptions,
              ),
              allRubricTables: useAggregatedQueries(
                allCompaniesIds.map((companyId) => ({
                  ...queriesOptions,
                  queryKey: ['getAllRubricTables', companyId],
                  queryFn: () =>
                    fetchGetAllRubricTables({
                      pathParams: {
                        organizationId,
                        companyId,
                      },
                    }),
                })),
              ),
            },
          }}
          Loading={() => <Typography>Carregando...</Typography>}
          Data={({ data }) => {
            const entries = parseAccountingEntries(data.allRubricTables);

            return (
              <Box className="ag-theme-quartz" height="100%">
                <AgGridReact
                  masterDetail={true}
                  autoSizeStrategy={{
                    type: 'fitGridWidth',
                  }}
                  rowData={fromApiDataToRowData(
                    data.entriesMappingRules,
                    entries,
                  )}
                  columnDefs={[
                    {
                      headerName: 'Nome',
                      field: 'name',
                      editable: true,
                      cellRenderer: 'agGroupCellRenderer',
                    },
                    {
                      headerName: 'Descrição',
                      field: 'description',
                      editable: true,
                    },
                  ]}
                  keepDetailRows
                  detailCellRenderer={detailCellRenderer}
                  detailCellRendererParams={{
                    costCenters: parseCostCenters(data.costCenters),
                    companies: parseCompanies(data.companies),
                    accounts: parseChartsOfAccounts(data.chartsOfAccounts),
                    entries,
                  }}
                  detailRowAutoHeight={true}
                  ref={gridRef}
                />
              </Box>
            );
          }}
        />
      </Box>

      <Box py={2}>
        <Button
          onClick={() => {
            const rows: RowData[] = [];
            gridRef.current.api.forEachNode((node) => {
              rows.push(node.data);
            });

            fetchPutEntriesMappingRules({
              body: {
                rules: rows
                  .filter((row) => {
                    return row.mappings.some((mapping) => {
                      return mapping.source.length > 0;
                    });
                  })
                  .map((row) => {
                    return {
                      name: row.name,
                      description: row.description,
                      filters: row.filters.filter((filter) =>
                        Object.values(filter).some((value) => value.length > 0),
                      ),
                      mappings: row.mappings.filter(
                        (mapping) =>
                          mapping.source.length > 0 &&
                          mapping.debit &&
                          mapping.credit,
                      ),
                    };
                  }),
              },
              pathParams: {
                periodId: period,
                organizationId,
              },
            }).then(() => {
              window.location.reload();
            });
          }}
        >
          Salvar
        </Button>
        <Button
          onClick={() => {
            const rows: RowData[] = [];
            gridRef.current.api.forEachNode((node) => {
              rows.push(node.data);
            });

            fetchPostEntriesMappingRulesCompile({
              pathParams: {
                periodId: period,
                organizationId,
              },
            }).then(() => {
              window.location.reload();
            });
          }}
        >
          Compilar
        </Button>
      </Box>
    </Box>
  );
}

function DetailCellRenderer({
  data,
  costCenters,
  companies,
  accounts,
  entries,
}: CustomCellRendererProps<RowData> & {
  costCenters: CostCenters;
  companies: Companies;
  accounts: Accounts;
  entries: AccountingEntries;
}) {
  const { filters, mappings } = data;

  const formatCostCenter = (costCenterId: string) => {
    if (!costCenterId) {
      return '';
    }

    const costCenter = costCenters[costCenterId];

    if (!costCenter) {
      return costCenterId;
    }

    return `${costCenter.name} (${costCenter.code})`;
  };
  const costCenterRenderer = (props: CustomCellRendererProps) => {
    if (!props.value) {
      return null;
    }

    return (
      <div>
        {(Array.isArray(props.value) ? props.value : [props.value]).map(
          (costCenterId: string) => {
            return (
              <Chip
                size="small"
                key={costCenterId}
                label={formatCostCenter(costCenterId)}
              />
            );
          },
        )}
      </div>
    );
  };

  const formatCompany = (companyId: string) => {
    if (!companyId) {
      return '';
    }

    const company = companies[companyId];

    if (!company) {
      return companyId;
    }

    return `${company.razaoSocial} (${formatCNPJ(company.cnpj)})`;
  };
  const companyRenderer = (props: CustomCellRendererProps) => {
    if (!props.value) {
      return null;
    }

    return (
      <div>
        {(Array.isArray(props.value) ? props.value : [props.value]).map(
          (companyId: string) => {
            return (
              <Chip
                size="small"
                key={companyId}
                label={formatCompany(companyId)}
              />
            );
          },
        )}
      </div>
    );
  };

  const formatAccount = (accountId: string) => {
    if (!accountId) {
      return '';
    }

    const account = accounts[accountId];

    if (!account) {
      return accountId;
    }

    return `${account.name} (${account.id})`;
  };
  const accountRenderer = (props: CustomCellRendererProps) => {
    if (!props.value) {
      return null;
    }

    return (
      <div>
        {(Array.isArray(props.value) ? props.value : [props.value]).map(
          (accountId: string) => {
            return (
              <Chip
                size="small"
                key={accountId}
                label={formatAccount(accountId)}
              />
            );
          },
        )}
      </div>
    );
  };

  const formatEntry = (entryId: string) => {
    if (!entryId) {
      return '';
    }

    const entry = entries[entryId];

    if (!entry) {
      return entryId;
    }

    return `${entry.name} (${entry.id})`;
  };

  const entryRenderer = (props: CustomCellRendererProps) => {
    if (!props.value) {
      return null;
    }

    return (
      <div>
        {(Array.isArray(props.value) ? props.value : [props.value]).map(
          (entryId: string) => {
            return (
              <Chip
                size="small"
                key={entryId}
                label={formatEntry(entryId)}
                sx={(() => {
                  if (!entryId) {
                    return {};
                  }

                  const entry = entries[entryId];

                  if (!entry) {
                    return {};
                  }

                  const backgroundColor = (
                    {
                      'rubrica/provento': 'green',
                      'rubrica/desconto': 'red',
                      'rubrica/informativa': 'blue',
                      'rubrica/informativaDedutora': 'orange',
                      encargo: '#CCCC00	',
                      provisoes: 'blue',
                    } as const
                  )[entry.type];

                  return {
                    color: 'white',
                    backgroundColor,
                  };
                })()}
              />
            );
          },
        )}
      </div>
    );
  };

  const editableOptions = {
    editable: true,
    cellEditor: 'agRichSelectCellEditor',
    autoHeight: true,
    wrapText: true,
  };

  const cellEditorParams = {
    suppressMultiSelectPillRenderer: true,
    multiSelect: true,
    searchType: 'matchAny',
    allowTyping: true,
    filterList: true,
    highlightMatch: true,

    valueListMaxHeight: 220,
    cellHeight: 40,
  };

  return (
    <Box px={3} py={1}>
      <Box py={2}>
        <Typography variant="h2">Filtros</Typography>
        <Typography sx={{ width: '100%', maxWidth: '700px', textWrap: 'wrap' }}>
          Cada linha descreve uma condição que o contrato deve passar para que
          essas regras se apliquem a ele. Funcionam como uma lógica de "E". Já
          as propriedades dentro de cada linha expressam uma lógica de "OU".
          <br />
          Exemplo: Se na primeira linha tiver selecionado a empresa A e a
          empresa B, e na segunda linha Centro de Custo X, todos os contratos
          que tenham o Centro de Custo X e que estejam dentro das Empresas A e B
          serão selecionados.
        </Typography>
      </Box>
      <Box className="ag-theme-quartz" height="180px">
        <AgGridReact
          autoSizeStrategy={{
            type: 'fitGridWidth',
          }}
          rowData={filters}
          columnDefs={[
            {
              headerName: 'Centros de Custos',
              field: 'costCenterId',

              ...editableOptions,

              cellRenderer: costCenterRenderer,
              cellEditorParams: {
                values: Object.keys(costCenters),
                ...cellEditorParams,
                formatValue: formatCostCenter,
              },
            },
            {
              headerName: 'Empresas',
              field: 'companyId',

              ...editableOptions,
              cellRenderer: companyRenderer,
              cellEditorParams: {
                values: Object.keys(companies),

                ...cellEditorParams,
                formatValue: formatCompany,
              },
            },
          ]}
        />
      </Box>

      <Box py={2}>
        <Typography variant="h2">Regras</Typography>
      </Box>
      <Box className="ag-theme-quartz" height="900px">
        <AgGridReact
          autoSizeStrategy={{
            type: 'fitGridWidth',
          }}
          rowData={mappings}
          columnDefs={[
            {
              field: 'description',
              headerName: 'Descrição',

              editable: true,
            },
            {
              field: 'source',
              headerName: 'Lançamentos',
              editable: true,

              cellRenderer: entryRenderer,
              ...editableOptions,
              cellEditorParams: {
                values: Object.keys(entries),

                ...cellEditorParams,
                multiSelect: true,
                formatValue: formatEntry,
              },
            },
            {
              field: 'debit',
              headerName: 'Conta de débito',

              cellRenderer: accountRenderer,
              ...editableOptions,
              cellEditorParams: {
                values: Object.keys(accounts),

                ...cellEditorParams,
                multiSelect: false,
                formatValue: formatAccount,
              },
            },
            {
              field: 'credit',
              headerName: 'Conta de crédito',

              cellRenderer: accountRenderer,
              ...editableOptions,
              cellEditorParams: {
                values: Object.keys(accounts),

                ...cellEditorParams,
                multiSelect: false,
                formatValue: formatAccount,
              },
            },
            {
              field: 'allocation',
              headerName: 'Rateio',
            },
          ]}
        />
      </Box>
    </Box>
  );
}

function parseCostCenters(costCenters: CostCenterSummary[]): CostCenters {
  return costCenters.reduce(
    (acc, costCenter) => ({
      ...acc,
      [costCenter.costCenterId]: {
        costCenterId: costCenter.costCenterId,
        name: costCenter.name,
        code: costCenter.code,
      },
    }),
    {} as CostCenters,
  );
}

function parseCompanies(companies: CompanyList): Companies {
  return companies.data.reduce(
    (acc, company) => ({
      ...acc,
      [company.companyId]: {
        companyId: company.companyId,
        cnpj: company.br.cnpj,
        razaoSocial: company.br.razaoSocial,
      },
    }),
    {} as Companies,
  );
}

function parseChartsOfAccounts(
  chartsOfAccounts: AccountingChartsOfAccounts,
): Accounts {
  const accounts: Accounts = {};

  Object.values(chartsOfAccounts.charts).forEach((chart) => {
    Object.values(chart.accounts).forEach((account) => {
      accounts[`${chart.id}/${account.id}`] = {
        id: `${chart.id}/${account.id}`,
        name: account.name,
      };
    });
  });

  return accounts;
}

function parseAccountingEntries(
  rubricsTables: AggregatedQueryResult<RubricsTableMap>['data'],
): AccountingEntries {
  const entries: AccountingEntries = {};

  rubricsTables.forEach(({ tables }) => {
    Object.values(tables).forEach(({ tableId, rubrics }) => {
      Object.values(rubrics).forEach(({ id, name, br: { tipo } }) => {
        const entryId = `rubrica.${tableId}.${id}`;

        entries[entryId] = {
          id: entryId,
          name,
          type: `rubrica/${tipo}`,
        };
      });
    });
  });

  Object.values(rubricasESocial).forEach(({ codigo, nome, tipo }) => {
    const entryId = `rubrica.${idsDasTabelasPadroes.eSocial}.${codigo}`;

    entries[entryId] = {
      id: entryId,
      name: nome,
      type: `rubrica/${
        (
          {
            '1': 'provento',
            '2': 'desconto',
            '3': 'informativa',
            '4': 'informativaDedutora',
          } as const
        )[tipo]
      }`,
    };
  });

  Object.values(rubricasProprietarias).forEach(({ codigo, nome, tipo }) => {
    const entryId = `rubrica.${idsDasTabelasPadroes.proprietaria}.${codigo}`;

    entries[entryId] = {
      id: entryId,
      name: nome,
      type: `rubrica/${
        (
          {
            '1': 'provento',
            '2': 'desconto',
            '3': 'informativa',
            '4': 'informativaDedutora',
          } as const
        )[tipo]
      }`,
    };
  });

  entries[lancamentosDerivados.liquidoDeRescisao.id] = {
    id: lancamentosDerivados.liquidoDeRescisao.id,
    name: lancamentosDerivados.liquidoDeRescisao.descricao,
    type: 'rubrica/provento',
  };

  Object.values(lancamentosDeEncargos).forEach(({ id, descricao }) => {
    entries[id] = {
      id,
      name: descricao,
      type: 'encargo',
    };
  });

  const provisionFields = [
    'provisionedInPeriod',
    'deposits',
    'withdrawals',
    'paymentsForFruition',
    'paymentsForIndemnification',
    'paymentsReversal',
    'writeOffs',
    'adjustments',
  ] as const;

  const provisionAccounts = [
    tiposDeProvisoesPadroes.ferias.ferias,
    tiposDeProvisoesPadroes.ferias.inssPatronal,
    tiposDeProvisoesPadroes.ferias.inssRat,
    tiposDeProvisoesPadroes.ferias.inssOutrasEntidades,
    tiposDeProvisoesPadroes.ferias.fgts,
    tiposDeProvisoesPadroes.decimoTerceiro.decimoTerceiro,
    tiposDeProvisoesPadroes.decimoTerceiro.inssPatronal,
    tiposDeProvisoesPadroes.decimoTerceiro.inssRat,
    tiposDeProvisoesPadroes.decimoTerceiro.inssOutrasEntidades,
    tiposDeProvisoesPadroes.decimoTerceiro.fgts,
  ];

  const provisionEntries = provisionAccounts.flatMap((account_id) => {
    return provisionFields.map((field) => ({
      entryType: `provisions.${account_id}.${field}`,
    }));
  });

  provisionEntries.forEach(({ entryType }) => {
    entries[entryType] = {
      id: entryType,
      name: entryTypeToLabel(entryType),
      type: 'provisoes',
    };
  });

  return entries;
}

function entryTypeToLabel(entryType: string): string {
  const [_, account, field] = entryType.split('.');

  const accountLabel =
    {
      [tiposDeProvisoesPadroes.ferias.ferias]: 'Férias / Gozo',
      [tiposDeProvisoesPadroes.ferias.inssPatronal]: 'Férias / INSS Patronal',
      [tiposDeProvisoesPadroes.ferias.inssRat]: 'Férias / INSS RAT',
      [tiposDeProvisoesPadroes.ferias.inssOutrasEntidades]:
        'Férias / INSS Outras Entidades',
      [tiposDeProvisoesPadroes.ferias.fgts]: 'Férias / FGTS',
      [tiposDeProvisoesPadroes.decimoTerceiro.decimoTerceiro]:
        'Décimo Terceiro / Décimo Terceiro',
      [tiposDeProvisoesPadroes.decimoTerceiro.inssPatronal]:
        'Décimo Terceiro / INSS Patronal',
      [tiposDeProvisoesPadroes.decimoTerceiro.inssRat]:
        'Décimo Terceiro / INSS RAT',
      [tiposDeProvisoesPadroes.decimoTerceiro.inssOutrasEntidades]:
        'Décimo Terceiro / INSS Outras Entidades',
      [tiposDeProvisoesPadroes.decimoTerceiro.fgts]: 'Décimo Terceiro / FGTS',
    }[account] || account;

  const fieldLabel =
    {
      provisionedInPeriod: 'Provisionado do Mês',
      deposits: 'Transf. Depósitos',
      withdrawals: 'Transf. Retiradas',
      paymentsForFruition: 'Pagamentos por Gozo',
      paymentsForIndemnification: 'Pagamentos por Indenização',
      paymentsReversal: 'Estorno',
      writeOffs: 'Cancelamentos',
      adjustments: 'Ajustes',
    }[field] || field;

  return `Prov. ${accountLabel} - ${fieldLabel}`;
}

type AggregatedQueryResult<T> = {
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  refetch: () => void;
  data?: T[];
};

function useAggregatedQueries<T>(
  queries: Array<{
    queryKey: any;
    queryFn: () => Promise<T>;
    refetchOnWindowFocus?: boolean;
  }>,
): AggregatedQueryResult<T> {
  const queryResults = useQueries({
    queries,
  });

  const isLoading = queryResults.some((result) => result.isLoading);
  const isError = queryResults.some((result) => result.isError);
  const error = queryResults.find((result) => result.error)?.error;

  // Collect data only if all queries have data
  const allDataAvailable = queryResults.every(
    (result) => result.data !== undefined,
  );
  const data = allDataAvailable
    ? queryResults.map((result) => result.data)
    : undefined;

  const refetch = () => {
    queryResults.forEach((result) => {
      result.refetch();
    });
  };

  return {
    isLoading,
    isError,
    error,
    refetch,
    data,
  };
}

export default Page;
