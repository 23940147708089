import React from 'react';

import { CheckCircle } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import { MobileDrawer } from '../../../modules/components/onboarding/dialogs';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export function MissingFieldsDialog({ open, setOpen }: Props) {
  const actions = (
    <Button
      variant="outlined"
      color="primaryAlt"
      sx={{ padding: 1.25 }}
      onClick={() => setOpen(false)}
    >
      Fechar Alerta
    </Button>
  );

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: 1,
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Typography variant="h2">Preencha os dados</Typography>
      <Typography variant="body1">
        Atenção, você ainda não preencheu todos os dados obrigatórios!
        <Typography variant="body1">
          Verifique os itens pendentes, que não estão marcados com icone{' '}
          <CheckCircle color="success" />
        </Typography>
      </Typography>
    </Box>
  );
  return (
    <>
      <MobileDrawer
        open={open}
        loading={false}
        setOpen={setOpen}
        actions={actions}
        content={content}
      ></MobileDrawer>
      <Dialog
        open={open}
        onClose={setOpen}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: { display: 'none' },
        })}
      >
        <DialogContent>{content}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    </>
  );
}
