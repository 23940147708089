import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { PersonRemoveOutlined } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  IconButton,
  Popover,
  Skeleton,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import {
  AdmissionDraftList,
  AdmissionDraftStatus,
  AdmissionDraftUpdateInput,
  AdmissionInviteInput,
  AdmissionType,
  SearchInput,
  fetchArchiveAdmissionDraft,
  fetchPostAdmissionInvite,
  fetchPutAdmissionDraft,
  fetchSearchAdmissionDrafts,
} from '@octopus/api';
import { contractTypes } from '@octopus/contract-types';
import { admissionDraftStatuses } from '@octopus/onboarding-types';
import {
  DataGrid,
  DataGridToolbar,
  FilterOptions,
  makeDateRangeFilter,
  makeElementListFilter,
  makeSimpleRangeFilter,
  useDataGrid,
} from '@octopus/ui/data-grid';
import { Button } from '@octopus/ui/design-system';

import {
  AdminTakeOverConfirmationDialog,
  DeleteConfirmationDialog,
  ResendInviteConfirmationDialog,
} from '../../modules/components/onboarding/dialogs';
import TabLabel from '../../modules/components/TabLabel';
import { DataFetching } from '../../modules/dataFetching';
import { useSnackbar } from '../../modules/hooks/useSnackbar';
import { prepareDataGridSearchInput } from '../../utils';

import { getHeaders } from './new/form/fetchUtils';
import { getEffectiveDate } from './new/form/inputUtils';
import { columnsByTab, getActionMenuCol } from './utils/columns';
import { AdmissionTabs, admissionTabsConfig } from './utils/types';

type AdmissionTableState = {
  totals:
    | {
        totalCount: number;
        current: number;
      }
    | undefined;
  filters: {
    titles: {
      id: string;
      name: string;
      count: number;
    }[];
    departments: {
      name: string;
      count: number;
    }[];
    contractTypes: {
      name: string;
      count: number;
    }[];
    legalEntities: {
      id: string;
      name: string;
    }[];
  };
};

function addTabFilters(searchInput: SearchInput, tab: AdmissionTabs) {
  if (!searchInput.filtering) {
    searchInput.filtering = {};
  }

  if (!searchInput.filtering.elements) {
    searchInput.filtering.elements = {};
  }

  if (!searchInput.filtering.toggles) {
    searchInput.filtering.toggles = [];
  }

  const tabConfig = admissionTabsConfig[tab];

  searchInput.filtering.elements['contractType'] = [
    contractTypes.brClt,
    contractTypes.brPj,
  ];
  searchInput.filtering.elements['draftStatus'] = [...tabConfig.draftStatus];
}

export function AdmissionTable({
  organizationId,
  showAdmitted,
  setShowAdmitted,
}: {
  organizationId: string | undefined;
  showAdmitted: boolean;
  setShowAdmitted: (value: boolean) => void;
}) {
  const { showSnackbar } = useSnackbar();
  const initialState: AdmissionTableState = {
    totals: undefined,
    filters: {
      titles: [],
      departments: [],
      contractTypes: [],
      legalEntities: [],
    },
  };
  const navigate = useNavigate();
  const [state, setState] = React.useState<AdmissionTableState>(initialState);
  const [showDeleteAdmissionDialog, setShowDeleteAdmissionDialog] =
    useState<boolean>(false);
  const [showAdminTakeOverDialog, setShowAdminTakeOverDialog] =
    useState<boolean>(false);
  const [showResendInviteDialog, setShowResendInviteDialog] =
    useState<boolean>(false);
  const [selectedAdmissionDraft, setSelectedAdmissionDraft] = useState<{
    draftId: string;
    name?: string;
    email?: string;
  }>(undefined);

  const filters = useFilters(state.filters);
  const dataGridProps = useDataGrid({
    filters,
  });

  const [tab, setTab] = useState<keyof typeof admissionTabsConfig>(
    AdmissionTabs.draft_created,
  );

  const { sortingProps, searchProps, paginationProps, filteringProps } =
    dataGridProps;

  const searchInput = prepareDataGridSearchInput(dataGridProps);

  addTabFilters(searchInput, tab);

  searchInput.counting = {
    filtered: {
      byProp: {
        contractType: Object.values(contractTypes),
        draftStatus: Object.values(admissionDraftStatuses),
      },
    },
    unfiltered: {
      byProp: {
        contractType: Object.values(contractTypes),
        draftStatus: Object.values(admissionDraftStatuses),
      },
    },
  };

  const queryKey = [organizationId, searchInput, paginationProps, tab];
  const admissionsQuery = useQuery({
    queryKey,
    refetchOnWindowFocus: false,
    queryFn: () => {
      return fetchSearchAdmissionDrafts({
        pathParams: {
          organizationId: organizationId ?? '',
        },
        body: searchInput,
      });
    },
    enabled: !!organizationId,
  });

  useEffect(() => {
    if (!admissionsQuery.isError && admissionsQuery.data) {
      const { metadata } = admissionsQuery.data;

      setState((state) => ({
        totals: {
          current: admissionsQuery.data.total,
          // TODO: remove the archived rows from opensearch
          totalCount:
            admissionsQuery.data.unfilteredTotal -
            (metadata?.filtered?.counters?.byProp['draftStatus']['archived'] ??
              0),
        },
        filters: {
          ...state.filters,
          ...admissionsQuery.data.filters,
          showAdmitted,
        },
      }));
    }
  }, [admissionsQuery.isError, admissionsQuery.data, showAdmitted]);

  const switchTab = (tab: string) => {
    setTab(tab as keyof typeof admissionTabsConfig);
  };

  const totals = (() => {
    if (admissionsQuery?.isFetching || admissionsQuery?.isLoading) {
      return undefined;
    }

    const current = state?.totals?.current ?? 0;
    const total = state?.totals?.totalCount ?? 0;

    return {
      current,
      all: total,
    };
  })();

  const [countByTab, setCountByTab] = useState<Record<string, number>>({});
  useEffect(() => {
    const statusCounters =
      admissionsQuery.data?.metadata?.filtered?.counters?.byProp?.draftStatus;
    if (statusCounters) {
      setCountByTab(statusCounters);
    }
  }, [admissionsQuery.data?.metadata?.filtered?.counters?.byProp?.draftStatus]);

  const getTabCount = ({
    tabConfig,
  }: {
    tabConfig: { draftStatus: AdmissionDraftStatus[] };
  }) => {
    const count = tabConfig.draftStatus.reduce(
      (acc: number, status: string) => {
        return acc + (countByTab?.[status] || 0);
      },
      0,
    );

    return count !== 0 ? count : undefined;
  };

  const onRowClick = ({
    draftId,
    contractId,
    tab,
  }: {
    draftId: string;
    contractId: string;
    tab: keyof typeof AdmissionTabs;
  }) => {
    if (tab === 'admission_done' && !contractId) {
      return;
    }

    if (tab === 'in_review' && draftId) {
      navigate(`/admissions/review/${draftId}`);
      return;
    }

    if (contractId) {
      navigate(`/people/${contractId}`);
    } else {
      navigate(`/admissions/new/${draftId}`);
    }
  };

  return (
    <>
      <Box
        paddingBottom={1.5}
        display={'flex'}
        width={'100%'}
        justifyContent={'space-between'}
      >
        <Box
          alignSelf="stretch"
          width={'100%'}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <DataGridToolbar
            filters={filters}
            searchProps={searchProps}
            filteringProps={filteringProps}
            totals={totals}
            typeOfResultLabel={'resultados'}
            sx={{
              width: '100%',
            }}
          >
            <Button
              variantSemantic="primary"
              to={'/admissions/new'}
              sx={{
                marginLeft: 'auto',
                justifySelf: 'flex-end',
              }}
            >
              <Typography
                variant={'body1'}
                fontWeight={500}
                color={'secondary.main'}
              >
                Nova admissão
              </Typography>
            </Button>
          </DataGridToolbar>
        </Box>
      </Box>
      <Box
        my={2}
        sx={(theme) => ({
          boxShadow: `0 -1px 0 ${theme.palette.strokes.light} inset`,
        })}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Tabs
          value={tab}
          onChange={(_, newTab) => switchTab(newTab)}
          textColor="inherit"
          data-testid="admissions-status-tabs"
          TabIndicatorProps={{
            sx: {
              bgcolor: `${admissionTabsConfig[tab].color}.main`,
            },
          }}
        >
          {Object.entries(admissionTabsConfig).map(([key, tabConfig]) =>
            tabConfig.key === 'admission_done' && !showAdmitted ? undefined : (
              <Tab
                key={key}
                value={key}
                icon={
                  <TabLabel
                    isSelected={key === tab}
                    count={getTabCount({ tabConfig })}
                    color={tabConfig.color}
                    label={tabConfig.label}
                  />
                }
                sx={{
                  ':hover': {
                    opacity: 0.75,
                  },
                }}
                data-testid={`people-contractType-tab-${key}`}
              />
            ),
          )}
        </Tabs>
        <ActionMenu
          showAdmitted={showAdmitted}
          setShowAdmitted={setShowAdmitted}
        />
      </Box>
      <DataFetching
        fetchResult={admissionsQuery}
        Loading={() => {
          return (
            <Box display="flex" flexDirection="column" gap="8px" pt={1}>
              <Skeleton variant="rounded" height={300} width="100%" />
            </Box>
          );
        }}
        Data={({ data }) => {
          const response = data;
          return (
            <Box mt={2}>
              {response ? (
                <DataGrid
                  sortingProps={sortingProps}
                  paginationProps={paginationProps}
                  totalRowCount={response.total || 0}
                  getRowId={(row) => row.draftId}
                  rows={response.data}
                  onRowClick={({ row }) =>
                    onRowClick({
                      draftId: row.draftId,
                      contractId: row.contractId,
                      tab,
                    })
                  }
                  columns={
                    getActionMenuCol(tab, showSnackbar)
                      ? [
                          ...columnsByTab[tab],
                          getActionMenuCol(tab, showSnackbar, {
                            setShowDeleteAdmissionDialog,
                            setShowAdminTakeOverDialog,
                            setShowResendInviteDialog,
                            setSelectedAdmissionDraft,
                          }),
                        ]
                      : columnsByTab[tab]
                  }
                />
              ) : null}
            </Box>
          );
        }}
      />
      <DeleteConfirmationDialog
        open={showDeleteAdmissionDialog}
        action={() => {
          return fetchArchiveAdmissionDraft({
            pathParams: {
              draftId: selectedAdmissionDraft.draftId,
              organizationId: organizationId,
            },
          }).then((_entry) =>
            removeAdmissionDraftFromList({
              selectedAdmissionDraft: selectedAdmissionDraft.draftId,
              admissionsQuery,
            }),
          );
        }}
        setOpen={setShowDeleteAdmissionDialog}
      />
      <AdminTakeOverConfirmationDialog
        open={showAdminTakeOverDialog}
        action={() => {
          const admissionList: AdmissionDraftList = admissionsQuery.data;
          const draft = admissionList.data.find(
            (row) => row.draftId === selectedAdmissionDraft.draftId,
          );

          const headers = getHeaders();
          const body: AdmissionDraftUpdateInput = {
            admissionType: 'admin_fills' as AdmissionType,
            draftStatus:
              'admission_partially_filled_admin' as AdmissionDraftStatus,
            effectiveStartDate: getEffectiveDate(),
            contractType: draft.contractType as 'br:clt',
          };

          return fetchPutAdmissionDraft({
            pathParams: {
              organizationId,
              draftId: selectedAdmissionDraft.draftId,
            },
            body,
            headers,
          });
        }}
        setOpen={setShowAdminTakeOverDialog}
      />
      {showResendInviteDialog && (
        <ResendInviteConfirmationDialog
          open={showResendInviteDialog}
          user_email={selectedAdmissionDraft?.email}
          user_name={selectedAdmissionDraft?.name}
          setOpen={setShowResendInviteDialog}
          action={({ email, name }: { email: string; name: string }) => {
            const admissionList: AdmissionDraftList = admissionsQuery.data;
            const draft = admissionList.data.find(
              (row) => row.draftId === selectedAdmissionDraft.draftId,
            );

            const body: AdmissionInviteInput = {
              contractType: draft.contractType as 'br:clt',
              companyId: draft.companyId,
              workerName: name,
              workerEmail: email,
              draftId: selectedAdmissionDraft.draftId,
            };

            return fetchPostAdmissionInvite({
              pathParams: {
                organizationId,
              },
              body,
            });
          }}
        />
      )}
    </>
  );
}

function useFilters({
  titles,
  departments,
  legalEntities,
}: AdmissionTableState['filters']): FilterOptions {
  return [
    makeElementListFilter({
      label: 'Cargo',
      propertyToFilter: 'title',
      elements: titles.map(({ id }) => id),
      labels: titles.reduce(
        (acc, { id, name }) => {
          acc[id] = name;
          return acc;
        },
        {} as Record<string, string>,
      ),
    }),
    makeElementListFilter({
      label: 'Departamento',
      propertyToFilter: 'department',
      elements: departments.map(({ name }) => name),
    }),
    makeDateRangeFilter({
      label: 'Data de admissão',
      propertyToFilter: 'admissionDate',
    }),
    makeDateRangeFilter({
      label: 'Data de convite',
      propertyToFilter: 'inviteDate',
    }),
    makeDateRangeFilter({
      label: 'Data limite',
      propertyToFilter: 'limitDate',
    }),
    makeSimpleRangeFilter({
      label: 'Progresso',
      propertyToFilter: 'doneSteps',
      getRangeMin: () => 0,
      getRangeMax: () => 7,
    }),
    ...(legalEntities.length > 1
      ? [
          makeElementListFilter({
            label: 'Empregador',
            propertyToFilter: 'legalEntityId.keyword',
            elements: legalEntities.map(({ id }) => id),
            labels: legalEntities.reduce(
              (acc, { id, name }) => {
                acc[id] = name;
                return acc;
              },
              {} as Record<string, string>,
            ),
          }),
        ]
      : []),
  ];
}

function removeAdmissionDraftFromList({
  selectedAdmissionDraft,
  admissionsQuery,
}: {
  selectedAdmissionDraft: string;
  admissionsQuery: UseQueryResult<AdmissionDraftList>;
}) {
  const admissionList: AdmissionDraftList = admissionsQuery.data;
  const index = admissionList.data.findIndex(
    (row) => row.draftId === selectedAdmissionDraft,
  );
  (admissionsQuery.data as AdmissionDraftList).data.splice(index, 1);
}

function ActionMenu({
  showAdmitted,
  setShowAdmitted,
}: {
  showAdmitted: boolean;
  setShowAdmitted: (value: boolean) => void;
}) {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);
  return (
    <Box display={'flex'} justifyContent="flex-end" alignItems={'flex-start'}>
      <IconButton
        size="small"
        onClick={(event) => {
          setOpen(true);
          event.stopPropagation();
        }}
        ref={menuRef}
        sx={{
          borderRadius: '8px',
          padding: '4px',
        }}
      >
        <MoreVertIcon
          fontSize="inherit"
          sx={{
            width: '24px',
            height: '24px',
          }}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <Box p={0.5} display={'flex'} alignItems={'center'}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            py={1}
            px={1.5}
            gap={2}
            alignItems={'center'}
          >
            <Box width={'16px'} height={'16px'} color={'text.secondary'}>
              <PersonRemoveOutlined />
            </Box>

            <Typography fontSize={'14px'} py={1} variant={'body1'}>
              Mostrar concluídas
            </Typography>
          </Box>
          <Box px={1}>
            <Switch
              checked={showAdmitted}
              onChange={() => {
                setShowAdmitted(!showAdmitted);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
