import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import {
  ApiContext,
  EventSourcingEventWithAuthor,
  GetContractEventsPageError,
  GetContractEventsPageQueryParams,
  fetchGetContractEventsPage,
  useApiContext,
} from '@octopus/api';

export type ContractHistoryEvent = Readonly<
  EventSourcingEventWithAuthor & {
    canceled: boolean;
  }
>;

export function useGetAllAuthoredContractEvents(
  {
    organizationId,
    contractId,
    pageSize = 50,
    sort = 'desc',
  }: {
    organizationId: string;
    contractId: string;
    pageSize?: number;
    sort?: 'asc' | 'desc';
  },
  options?: Omit<
    UseQueryOptions<
      ContractHistoryEvent[],
      GetContractEventsPageError,
      ContractHistoryEvent[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) {
  const { fetcherOptions, queryOptions } = useApiContext(options);

  return useQuery<
    ContractHistoryEvent[],
    GetContractEventsPageError,
    ContractHistoryEvent[]
  >({
    queryKey: [
      'getAllAuthoredContractEvents',
      organizationId,
      contractId,
      pageSize.toString(),
      sort,
    ],
    queryFn: ({ signal }) => {
      return fetchAllAuthoredContractEvents({
        organizationId,
        contractId,
        pageSize,
        sort,
        fetcherOptions,
        signal,
      });
    },
    ...options,
    ...queryOptions,
  });
}

async function fetchAllAuthoredContractEvents({
  organizationId,
  contractId,
  pageSize = 50,
  sort = 'desc',
  fetcherOptions,
  signal,
}: {
  organizationId: string;
  contractId: string;
  pageSize?: number;
  sort?: 'asc' | 'desc';
  signal: AbortSignal;
  fetcherOptions: ApiContext['fetcherOptions'];
}): Promise<ContractHistoryEvent[]> {
  let cursor;
  let events: EventSourcingEventWithAuthor[] = [];
  do {
    const queryParams: GetContractEventsPageQueryParams = {
      limit: pageSize.toString(),
      sort,
    };
    if (cursor) {
      queryParams.cursor = cursor;
    }
    const { data, nextCursor } = await fetchGetContractEventsPage(
      {
        ...fetcherOptions,
        pathParams: {
          organizationId,
          contractId,
        },
        queryParams,
      },
      signal,
    );

    cursor = nextCursor;
    events = events.concat(data);
  } while (cursor);

  return checkCanceledEvents(events);
}

function checkCanceledEvents(
  events: EventSourcingEventWithAuthor[],
): ContractHistoryEvent[] {
  const canceledEvents = events.reduce((acc, event) => {
    if (event.type === 'correction') {
      acc.add(event.payload.sequenceId);
    }
    return acc;
  }, new Set<number>([]));

  return events.map((event) => ({
    ...event,
    canceled: canceledEvents.has(event.sequenceId),
  }));
}
